<template>
  <iq-main>
    <div class="container">
      <div class="wrapper">
        <!-- loading -->
        <div class="holder" v-if="status === 'loading'">
          <md-progress-spinner
            md-mode="indeterminate"
            :md-stroke="3"
          ></md-progress-spinner>

          <div class="md-title">Bestätigung wird durchgeführt...</div>
        </div>

        <!-- confirmed -->
        <div class="holder" v-else-if="status === 'confirmed'">
          <md-icon class="md-size-3x md-primary">verified</md-icon>

          <div class="md-title">Ihr Account wurde erfolgreich bestätigt!</div>

          <md-button class="md-primary" :to="{ name: 'Authorization' }"
            >Jetzt anmelden</md-button
          >
        </div>

        <!-- error -->
        <div class="holder" v-else-if="status === 'failed'">
          <md-icon class="md-size-3x md-accent">bug_report</md-icon>

          <div class="md-title">
            Bestätigung ist fehlgeschlagen. Bitte versuchen Sie es später noch
            einmal!
          </div>
        </div>
      </div>
    </div>

    <iq-loader :loading="loading" />
  </iq-main>
</template>

<script>
import axios from "axios";
import Settings from "@/config";

const AUTH_URI = Settings.API_URI_AUTH;

export default {
  name: "Confirmation",
  data() {
    return {
      loading: false,
      status: "loading",
      stt: null,
    };
  },
  created() {
    this.getSTT();
  },
  mounted() {
    this.confirmUser();
  },
  methods: {
    getSTT() {
      let stt = this.$route.query.stt;
      if (stt === undefined) this.status = "failed";
      else if (stt.length === 0) this.status = "failed";
      else this.stt = stt;
    },
    confirmUser() {
      let params = new URLSearchParams();
      params.set("stt", this.stt);

      axios
        .post(
          `${AUTH_URI}/confirm/user`,
          {},
          {
            params: params,
          }
        )
        .then((response) => {
          console.log(response);
          this.status = "confirmed";
          this.toggleLoading(false);
        })
        .catch((error) => {
          console.error(error);
          this.status = "failed";
          this.toggleLoading(false);
        });
    },
    toggleLoading(state) {
      this.loading = state;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;

  & > .wrapper {
    border-radius: 0.5rem;
    border: 1px solid var(--iq-gray-300);
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    & > .holder {
      text-align: center;
    }
  }
}
</style>
